import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PUBLIC_PATH } from 'configs/AppConfig';
import GuidesAnalytics from "utils/GuidesAnalytics";

GuidesAnalytics.startSession()



export const AppPubViews = () => {

  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route path={`${APP_PUBLIC_PATH}/pub/`} component={lazy(() => import(`./pub`))} />

        <Route path={`${APP_PUBLIC_PATH}/pub/detail/:id/:text`} component={lazy(() => import(`./pub/detail`))} />
        <Route path={`${APP_PUBLIC_PATH}/pub/poiandmedia/:id/:text`} component={lazy(() => import(`./pub/poiandmedia`))} />

        <Redirect from={`/`} to={`${APP_PUBLIC_PATH}/pub/`} />
        {/* <Route path="/" render={() => <Redirect to={`${APP_PUBLIC_PATH}/pub/`} />} /> */}
      </Switch>
    </Suspense>
  )
}

export default AppPubViews;