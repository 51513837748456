import {
  DashboardOutlined,
  GlobalOutlined, CheckOutlined
} from '@ant-design/icons';
import { APP_ADMIN } from 'configs/AppConfig'

const dashBoardNavTree = [{
  key: 'dashboards',
  path: `${APP_ADMIN}/dashboards`,
  title: 'sidenav.dashboard',
  icon: DashboardOutlined,
  breadcrumb: true,
  submenu: [

    {
      key: 'dashboards-default',
      path: `${APP_ADMIN}/dashboards/default`,
      title: 'sidenav.dashboard.default',
      icon: DashboardOutlined,
      breadcrumb: false,
      submenu: []
    },


  ]
}];

const poiNavTree = [
  {
    key: 'poi',
    path: `${APP_ADMIN}/verifica`,
    title: 'admin.sidenav.gestione',
    icon: GlobalOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'addedit',
        path: `${APP_ADMIN}/poi/validazione`,
        title: 'admin.sidenav.da.validare',
        icon: CheckOutlined,
        breadcrumb: false,
        submenu: []
      }
    ]
  }
]



const navigationAdminConfig = [
  ...dashBoardNavTree,
  ...poiNavTree]

export default navigationAdminConfig;
