import { API, graphqlOperation } from 'aws-amplify';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { PLAYAUDIOABSTRACT_EVENT, PLAYAUDIO_EVENT } from './constatnts';
import { addAscoltoMedia, createStatisticheTest } from './mutuation';



class GuidesAnalytics {
    static sessionId = null;
    static startSession() {
        this.sessionId = sessionStorage.getItem('guidesanalyticsId');
        if (!this.sessionId) {
            this.sessionId = uuidv4();
            sessionStorage.setItem('guidesanalyticsId', this.sessionId);
        }

        return this.sessionId;
    }



    static getSession = () => {
        return this.sessionId;
    }

    static recordEvent = async (evento, attributes) => {
        console.log(evento, attributes)
        const d = new moment();
        API.graphql(
            graphqlOperation(createStatisticheTest, {
                input: {
                    PK: this.sessionId,
                    SK: `${evento}_${attributes.mediaId}_${d.format('YYYYMMDDHHmmss')}`,
                    created_at: (new Date()).toISOString(),
                    evento: evento,
                    mediaId: attributes.mediaId,
                    userId: attributes.userId
                }
            })).then((response) => {
                if (response.data.createStatisticheTest === "aggiorna") {
                    switch (evento) {
                        case PLAYAUDIO_EVENT:
                            API.graphql(
                                graphqlOperation(addAscoltoMedia, {
                                    PK: attributes.PK,
                                    SK: attributes.mediaId,
                                    fieldname: 'ascoltoAudio',
                                })
                            );
                            break;
                        case PLAYAUDIOABSTRACT_EVENT:
                            API.graphql(
                                graphqlOperation(addAscoltoMedia, {
                                    PK: attributes.PK,
                                    SK: attributes.mediaId,
                                    fieldname: 'ascoltoAbstract',
                                })
                            );
                            break;
                        default:


                    }
                }

            });



    }




}

export default GuidesAnalytics;