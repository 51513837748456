import React, { Component } from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { DownOutlined, CheckOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import { onLocaleChange } from 'redux/actions/Theme';
import lang from "assets/data/language.data.json";

function getLanguageDetail(locale) {
	const data = lang.filter(elm => (elm.langId === locale))
	return data[0]
}



const Selectedlanguage = ({ locale }) => {
	const language = getLanguageDetail(locale)
	const { langName, icon } = language
	return (
		<>
			<img style={{ maxWidth: '20px' }} src={`/img/flags/${icon}.png`} alt={langName} />
			<span className="ml-1">{langName}  <DownOutlined /></span>
		</>
	)
}




class NavLanguage extends Component {





	menu = () => (
		<div className="listeo">
			<Menu>
				{
					lang.map((elm, i) => {
						return (
							<Menu.Item key={i}
								className={this.props.locale === elm.langId ? 'ant-dropdown-menu-item-active' : ''}
								onClick={() => this.props.onLocaleChange(elm.langId)}>
								<span className="d-flex justify-content-between align-items-center">
									<div>
										<img style={{ maxWidth: '20px' }} src={`/img/flags/${elm.icon}.png`} alt={elm.langName} />
										<span className="font-weight-normal ml-2">{elm.langName}</span>
									</div>
									{this.props.locale === elm.langId ? <CheckOutlined className="text-success" /> : null}
								</span>
							</Menu.Item>
						)
					})
				}
			</Menu>
		</div>
	)




	render() {
		return (
			<Dropdown overlay={this.menu} trigger={['hover']}>
				<Button type="link" className="ant-dropdown-link" onClick={e => e.preventDefault()}>
					<Selectedlanguage locale={this.props.locale} />
				</Button>
			</Dropdown>

		)
	}
}

const mapStateToProps = ({ theme }) => {
	const { locale } = theme;
	return { locale }
};

export default connect(mapStateToProps, { onLocaleChange })(NavLanguage);
