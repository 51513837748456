import React, { useRef, useEffect, useState } from 'react';
//import { Hub,Auth } from 'aws-amplify';
import { connect } from 'react-redux';
// import navigationConfig from "configs/NavigationConfig";
// import utils from 'utils';
import AppPubViews from 'views/app-public-views';
import AudioPlayerPublic from 'components/shared-components/AudioPlayerPublic';
//import { AmplifySignIn } from '@aws-amplify/ui-react';
// import NavSearch from 'components/layoutpub-components/NavSearch';
// import { Link } from 'react-router-dom';
import { HeaderNav } from 'components/layoutpub-components/Header';
import { AuthState } from '@aws-amplify/ui-components';
import { AmplifyAuthenticator } from "@aws-amplify/ui-react";
import { Layout, Drawer, Button } from 'antd';
import Analytics from '@aws-amplify/analytics';
import { Link } from 'react-router-dom';
import { APP_PUBLIC_PATH } from 'configs/AppConfig';

export const CiviglioContext = React.createContext();
export const CiviglioConsumer = CiviglioContext.Consumer
const CiviglioProvider = CiviglioContext.Provider
const audioPlayerRef = React.createRef();
const loginDivRef = React.createRef();
// let fileUrl = '';
// let audioPlayer = (<></>);








export const PubLayout = ({ location, locale }) => {

  const audioPauseArray = [];

  //let audioEnded = false;
  let audioEndedFn = null;
  let audioPauseFn = null;
  let audioPlayFn = null;
  // const [authState, setAuthState] = React.useState();

  // const showSignIn = useRef(false);
  const mounted = useRef(false);
  const user = useRef(undefined);

  const [leftMenuVisible, setLeftMenuVisible] = useState(false);

  const mapObj = f => obj =>
    Object.keys(obj).reduce((acc, key) => ({ ...acc, [key]: f(obj[key]) }), {});
  const toArrayOfStrings = value => [`${value}`];
  const mapToArrayOfStrings = mapObj(toArrayOfStrings);

  useEffect(() => {
    mounted.current = true;
    return () => (mounted.current = false);
  }, []);

  const handleAuthStateChange = (nextAuthState, authData) => {
    if (nextAuthState === AuthState.SignedIn) {

      if (mounted.current) {
        user.current = authData;
        loginDivRef.current.classList.remove('active');
      }

      try {
        const { attributes } = authData;
        const userAttributes = mapToArrayOfStrings(attributes);
        Analytics.updateEndpoint({
          address: attributes.email,
          channelType: 'EMAIL',
          optOut: 'NONE',
          userId: attributes.sub,
          userAttributes,
        });
      } catch (error) {
        console.log(error);
      }

    }

  };

  const SignInDiv = () => {
    return (<div ref={loginDivRef} className="signIn" onClick={() => {
      loginDivRef.current.classList.remove('active');
    }}><div onClick={(e) => {
      e.stopPropagation();
    }}><AmplifyAuthenticator handleAuthStateChange={handleAuthStateChange}></AmplifyAuthenticator></div></div>)
  };




  //const currentRouteInfo = utils.getRouteInfo(navigationConfig, location.pathname)
  // const [showPlayer, setShowPlayer] = useState(false);
  //const [playAudioFile, setPlayAudioFile] = useState(''); 
  // const showPlayerFn = (audiofile) => {
  //   setPlayAudioFile(audiofile);
  //   setShowPlayer(!showPlayer);
  // }





  const setPlayedObjectFn = (poi) => {
    //audioEnded = false;

    if (audioPauseArray.length > 0) {
      const f = audioPauseArray.pop();
      f();
    }

    if (audioPauseFn) {
      audioPauseArray.push(audioPauseFn)
    }
    return audioPlayerRef.current.setPlayObject(poi);

  }

  const pauseAudio = () => {
    audioPlayerRef.current.pauseAudio();
  }

  const audioEnd = () => {
    if (audioEndedFn) audioEndedFn();
    //audioEnded=true;
  }

  const audioPause = () => {
    if (audioPauseFn) {
      audioPauseArray.push(audioPauseFn)
      audioPauseFn();
    }
  }

  const audioPlay = () => {
    if (audioPlayFn) audioPlayFn();
  }

  const setAudioEndFn = (fn) => {
    audioEndedFn = fn;
  }

  const setAudioPauseFn = (fn) => {
    audioPauseFn = fn;
  }

  const setAudioPlayFn = (fn) => {
    audioPlayFn = fn;
  }

  const showLoginFn = () => {
    //showSignIn.current=true;
    loginDivRef.current.classList.add('active');

  }

  const getUserFn = () => {
    //showSignIn=false;
    return user;
  }

  const onCloseDrawer = () => {

  }



  return (
    <>

      <div id="listeo-wrapper" className="listeo">
        <Layout>
          <SignInDiv />

          <HeaderNav openLeftMenu={() => {
            setLeftMenuVisible(true);
          }} closeLeftMenu={() => {
            setLeftMenuVisible(false);
          }} />
          <Drawer className='listeo'
            title={(<div className='mobile-nav'><div className='mobile-logo'><Link to={`${APP_PUBLIC_PATH}/home`}>
              <img src="/img/civiglio/civigliologo.png" alt="civiglio-logo" style={{ height: '60px' }} />
            </Link></div>
              <div className='close'>
                <Button type="link" className='btn-a-linkl' href="#" onClick={(e) => {
                  e.preventDefault();
                  setLeftMenuVisible(false)
                }}><i className="fas fa-arrow-left"></i></Button>
              </div>
            </div>)}
            placement="left"
            closable={false}
            onClose={onCloseDrawer}
            visible={leftMenuVisible}
            key="LEFT-DRAWER"
          >
            <p>
              <Link className="listeo-menu" to={`${APP_PUBLIC_PATH}/home`} onClick={() => {
                setLeftMenuVisible(false)
              }}>Home</Link>
            </p>
            <p>
              <Link className="listeo-menu" to="/app/poi/addeditpoi" onClick={() => {
                setLeftMenuVisible(false)
              }}>User</Link>
            </p>

          </Drawer>

          <div>
            <div style={{ overflow: 'hidden' }}>

              <CiviglioProvider value={{
                setPlayedObject: setPlayedObjectFn,
                pauseAudio: pauseAudio,
                setAudioEndFn: setAudioEndFn,
                setAudioPauseFn: setAudioPauseFn,
                setAudioPlayFn: setAudioPlayFn,
                showLogin: showLoginFn,
                getUser: getUserFn
              }}>
                <AppPubViews location={location} locale={locale} />



                <AudioPlayerPublic ref={audioPlayerRef} locale={locale} onAudioEnded={audioEnd} onPauseAudio={audioPause} onPlayAudio={audioPlay} showLogin={showLoginFn} />

              </CiviglioProvider>
            </div>

          </div>
        </Layout >
      </div >
    </>
  )
}

const mapStateToProps = ({ theme }) => {
  const { locale } = theme;
  return { locale }
};

export default connect(mapStateToProps)(React.memo(PubLayout));