import { Link } from "react-router-dom";
import { APP_PUBLIC_PATH } from 'configs/AppConfig'
import { Layout } from 'antd';
import NavLanguage from './NavLanguage';
import { MenuUnfoldOutlined } from '@ant-design/icons'

const { Header } = Layout;

const ListOption = ({ name, selector, disabled, vertical }) => (
  <div className={`${vertical ? '' : 'd-flex align-items-center justify-content-between'}`}>
    <div className={`${disabled ? 'opacity-0-3' : ''} ${vertical ? 'mb-3' : ''}`}>{name}</div>
    <div>{selector}</div>
  </div>
)

export const HeaderNav = props => {

  return (
    <>
      <Header theme="light">
        <div className="container">
          <div className="left-side">
            <div className="moblie-menu-opener">
              <button style={{ color: 'gray' }} onClick={(e) => {
                e.preventDefault();
                if (props.openLeftMenu) {
                  props.openLeftMenu();
                }

              }}>
                <MenuUnfoldOutlined style={{ fontSize: '22px' }} />
              </button>

            </div>
            <div className="logo">
              <Link to={`${APP_PUBLIC_PATH}/home`}>
                <img src="/img/civiglio/civigliologo.png" alt="civiglio-logo" style={{ height: '60px' }} />
              </Link>
            </div>
            <nav id="navigation" className="desk-navigation">
              <Link className="listeo-menu" to={`${APP_PUBLIC_PATH}/home`} >Home</Link>
              {/* <Link className="listeo-menu" to={`${APP_PUBLIC_PATH}/pub/poi/12345/mario`}>POI</Link> */}
              <Link className="listeo-menu" to="/app/poi/addeditpoi"  >User</Link>
            </nav>
          </div>
          <div className="right-side">

            <ListOption
              name=""
              selector={
                <NavLanguage configDisplay={true} />
              }
            />

          </div>
        </div>
      </Header>
    </>
  );
};