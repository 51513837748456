/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_user_files_s3_bucket": "civigliowebba8ebe1e07b047938f92b0cad411ac6f184458-daniele",
    "aws_user_files_s3_bucket_region": "eu-west-1",
    "aws_dynamodb_all_tables_region": "eu-west-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "statistiche-daniele",
            "region": "eu-west-1"
        },
        {
            "tableName": "civigliopoi-daniele",
            "region": "eu-west-1"
        },
        {
            "tableName": "media-daniele",
            "region": "eu-west-1"
        },
        {
            "tableName": "adminlog-daniele",
            "region": "eu-west-1"
        },
        {
            "tableName": "poi-daniele",
            "region": "eu-west-1"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-west-1:2f70addb-e178-45c2-8fae-05720a133507",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_XvNnY4rLM",
    "aws_user_pools_web_client_id": "69aops2j8u79d22rufup6f87s1",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "poiapi",
            "endpoint": "https://7yxgm2roub.execute-api.eu-west-1.amazonaws.com/daniele",
            "region": "eu-west-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://li7iebfo2fcidf7vakdzofjnfu.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-qcicpzgmvfdddjiubhictympw4",
    "aws_mobile_analytics_app_id": "4180102638da4f6dae9e46d3ab32196b",
    "aws_mobile_analytics_app_region": "eu-west-1"
};


export default awsmobile;
