import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_ADMIN } from 'configs/AppConfig';
import GuidesAnalytics from "utils/GuidesAnalytics";

GuidesAnalytics.startSession()

console.log('sessione', GuidesAnalytics.getSession())

export const AppBackendViews = () => {

    return (
        <Suspense fallback={<Loading cover="content" />}>
            <Switch>
                <Route path={`${APP_ADMIN}/home`} component={lazy(() => import(`./home`))} />
                <Route path={`${APP_ADMIN}/dashboards`} component={lazy(() => import(`./dashboard`))} />
                <Route path={`${APP_ADMIN}/poi`} component={lazy(() => import(`./poi`))} />
                <Redirect from={`/`} to={`${APP_ADMIN}/home`} />
            </Switch>
        </Suspense>
    )
}

export default AppBackendViews;