export const ROW_GUTTER = 16;
export const SIDE_NAV_WIDTH = 250;
export const SIDE_NAV_COLLAPSED_WIDTH = 80;
export const SIDE_NAV_LIGHT = 'SIDE_NAV_LIGHT';
export const SIDE_NAV_DARK = 'SIDE_NAV_DARK';
export const NAV_TYPE_SIDE = 'SIDE'
export const NAV_TYPE_TOP = 'TOP'
export const DIR_LTR = 'ltr'
export const DIR_RTL = 'rtl'
export const BORDER_RADIUS = '10px';
