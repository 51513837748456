import EnLang from './entries/en_US';
import ItLang from './entries/it_IT';
import FrLang from './entries/fr_FR';
import JaLang from './entries/ja_JP';
import ZhLang from './entries/zh_CN';

const AppLocale = {
    en: EnLang,
    it: ItLang,
    fr: FrLang,
    ja: JaLang,
    zh: ZhLang

};

export default AppLocale;