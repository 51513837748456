import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import AppBackendViews from 'views/app-backend-views';

import { withAuthenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import { Layout, Grid, Drawer } from 'antd';
import utils from 'utils';
import navigationAdminConfig from 'configs/NavigationAdminConfig';
import SideNav from 'components/layout-admin-components/SideNav';
import TopNav from 'components/layout-admin-components/TopNav';
import HeaderNav from 'components/layout-admin-components/HeaderNav';
import {
    SIDE_NAV_WIDTH,
    SIDE_NAV_COLLAPSED_WIDTH,
    NAV_TYPE_SIDE,
    NAV_TYPE_TOP
} from 'constants/ThemeConstant';
import PageHeader from 'components/layout-admin-components/PageHeader';
import { Scrollbars } from 'react-custom-scrollbars';
import MenuContent from '../../components/layout-admin-components/MenuContent';


const mapStateToProps = ({ theme }) => {
    const { navCollapsed, navType, locale } = theme;
    return { navCollapsed, navType, locale }
};

const MyDrawer = connect(mapStateToProps)(({ visible, placement, localization, routeInfo, closeBar }) => {
    const props = { routeInfo, localization }
    const [dVisible, setDVisible] = useState(visible)


    useEffect(() => {
        setDVisible(visible);
    }, [visible])

    return (
        <Drawer
            title="Civiglio Admin Menu"
            visible={dVisible}
            placement={placement}
            onClose={() => { closeBar() }}
        >
            <Scrollbars autoHide>
                <MenuContent
                    type={NAV_TYPE_SIDE}
                    {...props}
                />
            </Scrollbars>
        </Drawer>
    )
});


function BackendLayout(props) {
    const currentRouteInfo = utils.getRouteInfo(navigationAdminConfig, props.location.pathname)
    const { useBreakpoint } = Grid;
    const screens = utils.getBreakPoint(useBreakpoint());
    const [isauthenticated, setIsAuthenticated] = useState(false);
    const isMobile = !screens.includes('lg')
    const isNavSide = props.navType === NAV_TYPE_SIDE
    const isNavTop = props.navType === NAV_TYPE_TOP
    const [mobileDrawerVisible, setMobileDrawerVisible] = useState(false)
    const getLayoutGutter = () => {
        if (isNavTop || isMobile || !isauthenticated) {
            return 0
        }
        return props.navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH
    }


    Auth.currentAuthenticatedUser().then((value) => {
        setIsAuthenticated(true)
    }).catch(() => {
        setIsAuthenticated(false)
    });

    return (
        <Layout className='backend'>
            {isauthenticated && <HeaderNav isMobile={isMobile} openDrawer={(b) => {

                console.log('bool', b);
                console.log(isNavSide, isMobile, isauthenticated);
                setMobileDrawerVisible(b);

            }
            } />}
            <Layout className="app-container">
                {(isNavTop && !isMobile && isauthenticated) ? <TopNav routeInfo={currentRouteInfo} /> : null}
                {(isNavSide && !isMobile && isauthenticated) ? <SideNav routeInfo={currentRouteInfo} /> : null}
                {(isNavSide && isMobile && isauthenticated) ? <MyDrawer placement={'left'} routeInfo={currentRouteInfo} visible={mobileDrawerVisible}
                    localization={props.location} closeBar={() => {
                        setMobileDrawerVisible(false)
                    }} /> : null}
                <Layout className={`app-layout ${!isauthenticated ? 'app-not-logged' : ''}`} style={{ paddingLeft: getLayoutGutter() }}>
                    <div className={`app-content ${isNavTop ? 'layout-top-nav' : ''}`}>
                        <PageHeader display={currentRouteInfo?.breadcrumb} title={currentRouteInfo?.title} />
                        <Layout.Content>
                            <AppBackendViews />
                        </Layout.Content>
                    </div>


                </Layout>
            </Layout>
        </Layout>

    )
}


export default withAuthenticator(connect(mapStateToProps)(React.memo(BackendLayout)));