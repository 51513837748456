import React, {useState} from 'react';
import { Layout, Grid } from 'antd';
import { connect } from 'react-redux';

import SideNav from 'components/layout-components/SideNav';
import TopNav from 'components/layout-components/TopNav';
import MobileNav from 'components/layout-components/MobileNav'
import HeaderNav from 'components/layout-components/HeaderNav';
import PageHeader from 'components/layout-components/PageHeader';
import Footer from 'components/layout-components/Footer';
import AppViews from 'views/app-views';
import navigationConfig from "configs/NavigationConfig";
import { Auth, Hub } from 'aws-amplify';
// import store from 'redux/store';
// import { authenticated } from 'redux/actions/Auth'



import { 
  SIDE_NAV_WIDTH,
  SIDE_NAV_COLLAPSED_WIDTH,
  NAV_TYPE_SIDE,
  NAV_TYPE_TOP
} from 'constants/ThemeConstant';
import utils from 'utils';

const { Content } = Layout;
const { useBreakpoint } = Grid;

export const AppLayout = ({ navCollapsed, navType, location }) => {
  const currentRouteInfo = utils.getRouteInfo(navigationConfig, location.pathname)
  const screens = utils.getBreakPoint(useBreakpoint());
  const isMobile = !screens.includes('lg')
  const isNavSide = navType === NAV_TYPE_SIDE
  const isNavTop = navType === NAV_TYPE_TOP
  const getLayoutGutter = () => {
    if(isNavTop || isMobile || !isauthenticated) {
      return 0
    }
    return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH
  }

  const [isauthenticated, setIsAuthenticated] = useState(false);
  Auth.currentAuthenticatedUser().then((value) => {
    setIsAuthenticated(true)
  }).catch(()=>{
    setIsAuthenticated(false)
  });

  Hub.listen('auth', (data) => {
    switch (data.payload.event) {
      case 'signIn':
        setIsAuthenticated(true);
        Auth.currentSession()
        .then(data => {
          
          //store.dispatch(authenticated(authenticated(data.idToken)));
        })
        .catch(err => console.log(err));
       
        break;
      case 'signOut':
        setIsAuthenticated(false);
        break;
      default:
        setIsAuthenticated(false);
        break;

    }
  });
  
  return (
    <>
     <Layout>
      {isauthenticated && <HeaderNav isMobile={isMobile}/>}
      {(isNavTop && !isMobile && isauthenticated ) ? <TopNav routeInfo={currentRouteInfo}/> : null}
      <Layout className="app-container">
        {(isNavSide && !isMobile && isauthenticated) ? <SideNav routeInfo={currentRouteInfo}/> : null }
        <Layout className={`app-layout ${!isauthenticated ? 'app-not-logged':''}`} style={{paddingLeft: getLayoutGutter()}}>
          <div className={`app-content ${isNavTop ? 'layout-top-nav' : ''}`}>
            <PageHeader display={currentRouteInfo?.breadcrumb} title={currentRouteInfo?.title} />
            <Content>
              <AppViews />
            </Content>
          </div>
          {isauthenticated && <Footer />}
        </Layout>
      </Layout>
      {isMobile && <MobileNav />}
    </Layout>
   

    </>
  ) 
}

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType, locale } =  theme;
  return { navCollapsed, navType, locale }
};

export default connect(mapStateToProps)(React.memo(AppLayout));