import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import amplify from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import awsmobile from 'aws-exports';

amplify.configure(awsmobile);

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/profile`} component={lazy(() => import(`./profile`))} />
        <Route path={`${APP_PREFIX_PATH}/test`} component={lazy(() => import(`./test`))} />
        <Route path={`${APP_PREFIX_PATH}/home`} component={lazy(() => import(`./home`))} />
        <Route path={`${APP_PREFIX_PATH}/poi`} component={lazy(() => import(`./poi`))} />
        {/* <Route path={`${APP_PREFIX_PATH}/gql`} component={lazy(() => import(`./gql`))} /> */}
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
      </Switch>
    </Suspense>
  )
}

export default withAuthenticator(React.memo(AppViews));
