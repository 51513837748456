import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppLayout from "layouts/app-layout";
import PubLayout from 'layouts/pub-layout';
import BackendLayout from "layouts/backend-layout";
import AuthLayout from 'layouts/auth-layout';
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from 'antd';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH, APP_PUBLIC_PATH, APP_ADMIN } from 'configs/AppConfig';



console.log('View')

export const Views = (props) => {
  const { locale, location, playAudioFile } = props;

  const currentAppLocale = AppLocale[locale];
  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}>
      <ConfigProvider locale={currentAppLocale.antd}>
        <Switch>
          <Route exact path="/">
            <Redirect to={APP_PUBLIC_PATH} />

          </Route>



          <Route path={APP_PUBLIC_PATH}>
            <PubLayout location={location} locale={locale} playAudioFile={playAudioFile} />
          </Route>
          <Route path={APP_ADMIN}>
            <BackendLayout location={location} locale={locale} />
          </Route>
          <Route path={AUTH_PREFIX_PATH}>
            <AuthLayout />
          </Route>
          <Route path={APP_PREFIX_PATH}>
            <AppLayout location={location} />
          </Route>
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  )
}

const mapStateToProps = ({ theme, auth }) => {
  const { locale, playAudioFile } = theme;
  const { token } = auth;
  return { locale, playAudioFile, token }
};

export default withRouter(connect(mapStateToProps)(Views));