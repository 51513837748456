import React from 'react';
import { PlayCircleOutlined, PauseCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Button, Row, Col, Slider, Modal } from 'antd';
import { API_CIVIGLIO, CLOUDFRONT_URL } from 'constants/ApiConstant';
import IntlMessage from 'components/util-components/IntlMessage';

import axios from 'axios';
import Auth from '@aws-amplify/auth';

/*
import Amplify, {Storage} from 'aws-amplify';
import { camelCase } from 'lodash';
import { withRouter } from 'react-router-dom';
*/

const isMobile = window.innerWidth < 768


//let currentAudio = null;
class AudioPlayerPublic extends React.Component {
  state = {}
  audioRef = React.createRef();
  audioPlayerStyle = isMobile ? {
    bottom: '20px',
    background: 'rgba(70,70,70,.60)',
    width: 'calc(100vw - 10px)',
    borderRadius: '10px',
    padding: '10px',
    color: 'white',
    fontWeight: 'bold',
    margin: 'auto'

  } : {
    bottom: '20px',
    background: 'rgba(70,70,70,.60)',
    width: 'calc(100vw - 100px)',
    borderRadius: '10px',
    padding: '10px',
    color: 'white',
    fontWeight: 'bold',
    margin: 'auto'

  }
  constructor(props) {
    super(props);
    const fileUrl = props.audioMediaItem && props.playObject.audioMediaItem.audioFile;
    this.state = {
      audioMediaItem: null,
      audioPlayed: false,
      fileUrl: CLOUDFRONT_URL + '/audio/' + fileUrl,
      display: 'none',
      playaudioprogressstring: '00:00',
      playaudioprogress: 0,
      playaudiodurationstring: '00:00',
      playaudioduration: 0,
      modalcontenutoapagamento: false,
      contenutopagato: false,
      modalpagamentofallito: false,
      userauteticated: false,
      showLoginPopup: false
    }
  }

  showLoginPopup = () => {
    if (this.props.showLogin)
      this.props.showLogin();
  }

  componentDidMount() {
    // Auth.currentSession().then((session, err) => {
    //   console.log('session', session);
    //   console.log('error', err);
    // }).catch((err) => {
    //   console.log('catch err', err);
    // })
  }

  formatNumber = (number) => {
    return number < 10 ? '0' + number.toString() : number.toString();
  }

  onLoadedMetadata = (event) => {
    if (this.audioRef.current) {
      const seconds = this.audioRef.current.duration;
      const m = this.formatNumber(Math.floor(seconds / 60));
      const s = this.formatNumber(Math.floor(seconds - m * 60));
      this.setState({ playaudiodurationstring: m + ':' + s, playaudioduration: this.audioRef.current.duration });
    }

  }

  audioProgress = (event) => {

    const seconds = this.audioRef.current.currentTime;
    const m = this.formatNumber(Math.floor(seconds / 60));
    const s = this.formatNumber(Math.floor(seconds - m * 60));


    this.setState({ playaudioprogressstring: m + ':' + s, playaudioprogress: seconds });

  }

  playAudio = () => {
    this.setState({ audioPlayed: true });
    const audio = this.audioRef.current;
    if (this.props.onPlayAudio) {
      this.props.onPlayAudio();
    }
    audio.play();
  }

  closeAudioControl = () => {
    this.setState({ audioPlayed: false, display: 'none' });
    const audio = this.audioRef.current;
    if (this.props.onPauseAudio) {
      this.props.onPauseAudio();
    }
    audio.pause();
  }

  stopAudio = () => {

  }

  setPlayObject = (audioMediaItem) => {

    if (this.props.onPauseAudio) {
      this.props.onPauseAudio();
    }
    let fileUrl = '';
    if (audioMediaItem.price > 0 && !this.state.contenutopagato) {
      fileUrl = audioMediaItem.audioExtract;

    } else {
      fileUrl = audioMediaItem.audioFile;
    }

    if (fileUrl !== '') {
      this.setState({ audioPlayed: true, fileUrl: fileUrl, display: 'block', audioMediaItem: audioMediaItem, contenutopagato: false }, () => {

      });
      const audio = this.audioRef.current;
      //currentAudio=fileUrl;
      audio.src = CLOUDFRONT_URL + '/audio/' + fileUrl;
      audio.load();
      audio.play();
    } else {
      alert('NO AUDIO');
      return false;
    }
    return true;
  }

  // startAudio = (poiObject) =>{



  //     const fileUrl = poiObject.languages[this.props.locale].audioFile;
  //     this.setState({audioPlayed:true,fileUrl:fileUrl,display:'block', currentPoiObject:poiObject});
  //     const audio = this.audioRef.current;
  //     //currentAudio=fileUrl;
  //     audio.src = CLOUDFRONT_URL + '/audio/' +fileUrl;
  //     audio.load();
  //     audio.play();
  // }

  audioEnded = () => {
    this.setState({ audioPlayed: false });
    if (!this.state.contenutopagato && this.state.audioMediaItem.price > 0) {
      this.setState({ modalcontenutoapagamento: true });
    }
    if (this.props.onAudioEnded) {
      this.props.onAudioEnded();
    }
  }

  pauseAudio = () => {
    const audio = this.audioRef.current;
    audio.pause();
    if (this.props.onPauseAudio) {
      this.props.onPauseAudio();
    }
    this.setState({ audioPlayed: false });
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    return null;
  }



  render() {



    return (

      <div style={{ display: this.state.display }} className="audio-player-public-main">
        <div style={this.audioPlayerStyle} className="audio-player-public">
          <Button type="link" className="audio-player-close" onClick={() => this.closeAudioControl()}>
            <CloseCircleOutlined />
          </Button>
          <div className="rec-timer">
            {this.state.fileUrl && (<audio ref={this.audioRef} onLoadedMetadata={this.onLoadedMetadata} onTimeUpdate={this.audioProgress} onEnded={this.audioEnded}>
              <source src={`${CLOUDFRONT_URL}/audio/${this.state.fileUrl}`} type="audio/mp3" />
            </audio>)}
          </div>
          <Row gutter={16} justify='center'>
            <Col xs={6} md={3} padding={5} className='hide-mobile'>
              {this.state.audioMediaItem && <div style={{ backgroundImage: `url(${CLOUDFRONT_URL}/images/${this.state.audioMediaItem.immagine == null ? this.state.audioMediaItem.geopoi.immagine : this.state.audioMediaItem.immagine})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', width: '100%', height: '100%', borderRadius: '8px', backgroundPosition: 'cover' }} ></div>}
              {/* audioFile && <img style={{width:'100%',maxHeight:'100%'}} src={this.state.currentPoiObject.image} />} */}
            </Col>
            <Col xs={22} md={18}>
              <Row justify='center'>
                {!this.state.audioPlayed && (<Col xs={5} align="center"><Button shape="circle" onClick={this.playAudio}><PlayCircleOutlined /></Button></Col>)}
                {this.state.audioPlayed && (<Col xs={5} align="center"><Button shape="circle" onClick={this.pauseAudio}><PauseCircleOutlined /></Button></Col>)}
              </Row>
              <Row justify="center" align="top">

                <Col xs={4} style={{ paddingTop: '12px' }} align="right">
                  <span>{this.state.playaudioprogressstring}</span>
                </Col>
                <Col xs={16}>
                  <Slider
                    value={(this.state.playaudioprogress / this.state.playaudioduration) * 100}
                    showInfo={false} onChange={(value) => {
                      this.audioRef.current.currentTime = this.state.playaudioduration * value / 100;
                    }
                    } />
                </Col>
                <Col xs={4} style={{ paddingTop: '12px' }}>
                  <span>{this.state.playaudiodurationstring}</span>
                </Col>
              </Row>

            </Col>
            <Col xs={6} md={3} className='hide-mobile'></Col>
          </Row>

          <Modal
            title="Contenuto a pagamento"
            visible={this.state.modalcontenutoapagamento}
            onOk={() => {
              // eslint-disable-next-line no-restricted-globals
              Auth.currentSession().then((session) => {

                Auth.currentAuthenticatedUser().then((user) => {
                  console.log(`${API_CIVIGLIO}/paga-contenuto`, this.state);
                  axios.post(`${API_CIVIGLIO}/paga-contenuto`, { user: user, poi: this.state.audioMediaItem }).then((response) => {
                    if (response.data) {
                      this.setState({ contenutopagato: true }, () => {
                        const audio = this.audioRef.current;
                        audio.src = CLOUDFRONT_URL + '/audio/' + this.state.audioMediaItem.audioFile;
                        audio.load();
                        audio.play();
                      });
                    } else {
                      this.setState({ modalpagamentofallito: true });
                    }
                  })

                }).catch(err => console.log(err))




                this.setState({ modalcontenutoapagamento: false });
              }).catch((err) => {
                console.log(err);
                this.setState({ modalcontenutoapagamento: false }, this.showLoginPopup());

              })

            }}
            onCancel={() => {
              this.setState({ modalcontenutoapagamento: false });
            }}>
            <p><IntlMessage id="audioplayerpublic.contenuto.a.pagamento" /></p>
          </Modal>
          <IntlMessage id="audioplayerpublic.contenuto.a.pagamento.fallito.title">
            {
              (msg) => <Modal title={msg} visible={this.state.modalpagamentofallito} onOk={() => this.setState({ modalpagamentofallito: false })}>
                <p>
                  <IntlMessage id="audioplayerpublic.contenuto.a.pagamento.fallito" />
                </p>
              </Modal>
            }
          </IntlMessage>


        </div>

      </div>

    );
  }
}

export default AudioPlayerPublic;